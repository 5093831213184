<script>
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      transactions: [],
      transaction: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchModel: "",
      searchMode: false,
    };
  },
  methods: {
    get(page) {
      console.log(page);
      this.http
        .post("transactions/details/pagination", {
          limit: this.limit,
          page: page,
          id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.transactions = res.data;
          this.transaction = res.data[0];
        });
    },
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
  <Layout>
    <div class="row" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/journal_entries`">
            <h4 class="m-0">
              {{ $t("journal.title") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>#{{ transaction?.transaction_id }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6"></div>
              <div
                class="col-6"
                style="display: flex; justify-content: flex-end"
              >
                <router-link to="/add_journal">
                  <button type="button" class="btn btn-light float-end mb-4">
                    <span
                      class="bx bxs-plus-circle float-end fa-2x"
                      style="color: #2a3042 !important"
                    ></span>
                  </button>
                </router-link>
              </div>
            </div>

            <!-- Start  table -->
            <table
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("journal.date") }}</th>
                  <th scope="col">{{ $t("journal.id") }}</th>
                  <th scope="col">Account</th>
                  <th scope="col">{{ $t("journal.tr_descr") }}</th>
                  <th scope="col">{{ $t("journal.credit") }}</th>
                  <th scope="col">{{ $t("journal.debit") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(transaction, index) in transactions"
                  :key="transaction"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ transaction?.created?.split("T")[0] }},
                    {{ transaction?.created?.split("T")[1]?.split(".")[0] }}
                  </td>
                  <td>#{{ transaction?.code }}</td>
                  <td>{{$i18n.locale == "ar" ? transaction?.name :transaction?.name_en  }}</td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? transaction?.descr
                          ? transaction?.descr
                          : transaction?.descr
                        : transaction?.descr_en
                        ? transaction?.descr_en
                        : transaction?.descr
                    }}
                  </td>
                  <td>
                    {{
                      transaction?.type == "credit"
                        ? parseFloat(transaction?.value).toLocaleString()
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      transaction?.type == "debit"
                        ? parseFloat(transaction?.value).toLocaleString()
                        : ""
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- end  table -->

            <!--  Start Apps  Pagination     -->
            <ul
              class="pagination pagination-rounded justify-content-center mb-2"
            >
              <li class="page-item" :class="{ disabled: page == 1 }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page - 1)"
                  aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li
                class="page-item"
                :class="{ active: p == page }"
                v-for="p in tot_pages"
                :key="p"
              >
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(p)"
                  >{{ p }}</a
                >
              </li>

              <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page + 1)"
                  aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
            <!-- End  Apps  Pagination     -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
td,
th,
tr {
  padding: 1rem;
}
</style>
